import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {PortalMeetingAnalysis} from './portal-meeting-analysis.model';
import * as PortalMeetingAnalysisActions from './portal-meeting-analysis.actions';

export const portalMeetingAnalysissFeatureKey = 'portalMeetingAnalysis';

export interface State extends EntityState<PortalMeetingAnalysis> {
  // additional entities state properties
}

export function sortById(a: PortalMeetingAnalysis, b: PortalMeetingAnalysis): any {
  // return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<PortalMeetingAnalysis> = createEntityAdapter<PortalMeetingAnalysis>({
  // sortComparer: sortById
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const PortalMeetingAnalysisReducer = createReducer(
  initialState,
  on(PortalMeetingAnalysisActions.addPortalMeetingAnalysis,
    (state, action) => adapter.addOne(action.portalMeetingAnalysis, state)
  ),
  on(PortalMeetingAnalysisActions.upsertPortalMeetingAnalysis,
    (state, action) => adapter.upsertOne(action.portalMeetingAnalysis, state)
  ),
  on(PortalMeetingAnalysisActions.addPortalMeetingAnalysiss,
    (state, action) => adapter.addMany(action.portalMeetingAnalysiss, state)
  ),
  on(PortalMeetingAnalysisActions.upsertPortalMeetingAnalysiss,
    (state, action) => adapter.upsertMany(action.portalMeetingAnalysiss, state)
  ),
  on(PortalMeetingAnalysisActions.updatePortalMeetingAnalysis,
    (state, action) => adapter.updateOne(action.portalMeetingAnalysis, state)
  ),
  on(PortalMeetingAnalysisActions.updatePortalMeetingAnalysiss,
    (state, action) => adapter.updateMany(action.portalMeetingAnalysiss, state)
  ),
  on(PortalMeetingAnalysisActions.deletePortalMeetingAnalysis,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PortalMeetingAnalysisActions.deletePortalMeetingAnalysiss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PortalMeetingAnalysisActions.loadPortalMeetingAnalysiss,
    (state, action) => adapter.setAll(action.portalMeetingAnalysiss, state)
  ),
  on(PortalMeetingAnalysisActions.clearPortalMeetingAnalysiss,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return PortalMeetingAnalysisReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getPortalMeetingAnalysiss = selectAll;
