import gql from 'graphql-tag';
import {responseField} from '../../base.graphql';
import {userField} from '../../settings/user/user.graphql';

export const portalAgendaAnalysisField = `
id
uniqueId
review
documentName
documentPath
meetingAgenda {
  id
  uniqueId
  title
}
reviewedBy {
  ${userField}
}
currentVersion
createdDate
`;

export const SAVE_MINISTRY_PAPER_REVIEWS_MUTATION = gql`
mutation SaveMinistryPaperReviewsMutation($input: MinistryPaperReviewsInputObject) {
  saveMinistryPaperReviewsMutation(input: $input) {
    response {
      ${responseField}
    }
    data {
      ${portalAgendaAnalysisField}
    }
  }
}
`;


export const GET_MINISTRY_AGENDA_REVIEWS_HISTORY = gql`
query GetMinistryAgendaReviewsHistory($agendaUniqueId: String) {
  getMinistryAgendaReviewsHistory(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${portalAgendaAnalysisField}
    }
  }
}
`;


export const GET_MINISTRY_AGENDA_REVIEW = gql`
query GetMinistryAgendaReview($agendaUniqueId: String) {
  getMinistryAgendaReview(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      ${portalAgendaAnalysisField}
    }
  }
}
`;
