import {APP_INITIALIZER, NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {DatePipe} from '@angular/common';
import {HttpRequestInterceptor} from './shared/interceptors/http-request.interceptor';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {metaReducers, reducers} from './store/entities';
import {RoleEffects} from './store/entities/settings/role/role.effects';
import {PermissionEffects} from './store/entities/settings/permission/permission.effects';
import {UserEffects} from './store/entities/settings/user/user.effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPermissionsModule, NgxPermissionsService} from 'ngx-permissions';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {AttachmentEffects} from './store/entities/settings/attachment/attachment.effects';
import {AuthService} from './services/auth.service';
import {PageEffects} from './store/entities/page/page.effects';
import {InstitutionEffects} from './store/entities/settings/institution/institution.effects';
import {CommitteeEffects} from './store/entities/settings/committee/committee.effects';
import {FinancialYearEffects} from './store/entities/settings/financial-year/financial-year.effects';
import {ProjectCategoryEffects} from './store/entities/settings/project-category/project-category.effects';
import {ProjectEffects} from './store/entities/projects/project/project.effects';
import {DocumentEffects} from './store/entities/document/document.effects';
import {DocumentStageEffects} from './store/entities/settings/document-stage/document-stage.effects';
import {DocumentCategoryEffects} from './store/entities/settings/document-category/document-category.effects';
import {DecisionEffects} from './store/entities/meetings/decision/decision.effects';
import {LocationEffects} from './store/entities/settings/location/location.effects';
import {DirectiveEffects} from './store/entities/meetings/directive/directive.effects';
import {ForthcomingEffects} from './store/entities/document/forthcoming/forthcoming.effects';
import {GraphQLModule} from './graphql.module';
import {DesignationEffects} from './store/entities/settings/designation/designation.effects';
import {SmsConfigEffects} from './store/entities/settings/sms-config/sms-config.effects';
import {MailConfigEffects} from './store/entities/settings/mail-config/mail-config.effects';
import {FolderCategoryEffects} from './store/entities/settings/folder-category/folder-category.effects';
import {ScheduledMeetingEffects} from './store/entities/meetings/scheduled-meeting/scheduled-meeting.effects';
import {MeetingAgendaEffects} from './store/entities/meetings/meeting-agenda/meeting-agenda.effects';
import {MinutesNotesEffects} from './store/entities/meetings/minutes-notes/minutes-notes.effects';
import {MinutesDirectivesEffects} from './store/entities/meetings/minutes-directives/minutes-directives.effects';
import {MinutesDecisionsEffects} from './store/entities/meetings/minutes-decisions/minutes-decisions.effects';
import {ProjectComponentEffects} from './store/entities/projects/project-component/project-component.effects';
import {
  ProjectComponentTaskEffects
} from './store/entities/projects/project-component-task/project-component-task.effects';
import {ProjectChallengeEffects} from './store/entities/projects/project-challenge/project-challenge.effects';
import {
  ProjectRecommendationEffects
} from './store/entities/projects/project-recommendation/project-recommendation.effects';
import {RecommendationsEffects} from './store/entities/meetings/recommendations/recommendations.effects';
import {ProjectProgressEffects} from './store/entities/projects/project-progress/project-progress.effects';
import {DirectiveProgressEffects} from './store/entities/meetings/directives-progress/directives-progress.effects';
import {ProjectImageEffects} from './store/entities/projects/project-image/project-image.effects';
import {ForumMemberEffects} from './store/entities/settings/forum-member/forum-member.effects';
import {TaskEffects} from './store/entities/tasks/task/task.effects';
import {TaskResponseEffects} from './store/entities/tasks/task-response/task-response.effects';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxIndexedDBModule} from 'ngx-indexed-db';
import {INDEX_DB_CONFIGURATION} from './shared/constants/data.const';
import {TranslationEffects} from './store/entities/settings/translation/translation.effects';
import {
  AttachmentConfigurationEffects
} from './store/entities/settings/attachment-configuration/attachment-configuration.effects';
import {AttendanceEffects} from './store/entities/meetings/attendance/attendance.effects';
import {InviteeEffects} from './store/entities/meetings/invitee/invitee.effects';
import {RepresentativeEffects} from './store/entities/meetings/representative/representative.effects';
import {CONFIGURATION_TOKEN} from './app.tokens';
import {
  AssignmentConfigurationEffects
} from './store/entities/settings/assignment-configuration/assignment-configuration.effects';
import {DeviceEffects} from './store/entities/settings/device/device.effects';
import {NgxMatTimelineModule} from 'ngx-mat-timeline';
import {ProjectRepositoryEffects} from './store/entities/projects/project-repository/project-repository.effects';
import {QuillConfigModule, QuillModule} from 'ngx-quill';
import {DocumentCommentEffects} from './store/entities/document/document-comment/document-comment.effects';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DocumentationEffects} from './store/entities/document/documentation/documentation.effects';
import {DocumentationFileEffects} from './store/entities/document/documentation-file/documentation-file.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import {Apollo} from "apollo-angular";
import {CustomTranslateLoader} from "./services/custom-translate-loader";
import {
  PortalMeetingAnalysisEffects
} from "./store/entities/meetings/portal-meeting-analysis/portal-meeting-analysis.effects";
import {
  PortalAgendaAnalysisEffects
} from "./store/entities/meetings/portal-agenda-analysis/portal-agenda-analysis.effects";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    NgxIndexedDBModule.forRoot(INDEX_DB_CONFIGURATION),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [Apollo]
      }
    }),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    NgxMaterialTimepickerModule,
    ReactiveFormsModule,
    NgxMatTimelineModule,
    SharedModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
      RoleEffects, PermissionEffects, PageEffects, InstitutionEffects, CommitteeEffects, ProjectCategoryEffects,
      RoleEffects, PermissionEffects, UserEffects, AttachmentEffects, FinancialYearEffects, ProjectEffects,
      DocumentEffects, DocumentStageEffects, DocumentCategoryEffects, LocationEffects, DecisionEffects, DeviceEffects,
      DirectiveEffects, ForthcomingEffects, DesignationEffects, SmsConfigEffects, MailConfigEffects, FolderCategoryEffects,
      ScheduledMeetingEffects, MeetingAgendaEffects, MinutesNotesEffects, MinutesDirectivesEffects, MinutesDecisionsEffects,
      ProjectComponentEffects, ProjectComponentTaskEffects, RecommendationsEffects, ProjectProgressEffects,
      ProjectChallengeEffects, ProjectRecommendationEffects, ProjectImageEffects, ForumMemberEffects,
      DirectiveProgressEffects, TaskEffects, TaskResponseEffects, TranslationEffects, AttendanceEffects, AttachmentConfigurationEffects,
      InviteeEffects, RepresentativeEffects, AssignmentConfigurationEffects, ProjectRepositoryEffects,
      DocumentCommentEffects, DocumentationEffects, DocumentationFileEffects, PortalMeetingAnalysisEffects, PortalAgendaAnalysisEffects
    ]),
    HttpClientModule,
    AppRoutingModule,
    GraphQLModule,
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }],               // custom button values
          [{ list: 'ordered'}, { list: 'bullet' }],
          [{ script: 'sub'}, { script: 'super' }],      // superscript/subscript
          [{ indent: '-1'}, { indent: '+1' }],          // outdent/indent
          [{ direction: 'rtl' }],                         // text direction

          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
          [
            { font:
              [
                'sans-serif', 'serif', 'monospace',
                // 'Quicksand-Bold', "Roboto Condensed", "Times New Roman", "Calibri", "Calibri Light",
                // 'Roboto', 'Arial', 'Courier', 'Garamond', 'Tahoma', 'Verdana', 'quicksand'
              ]
            }
          ],
          [{ align: [] }],

          ['clean'],                                         // remove formatting button

          // ['link', 'image', 'video']
        ]
      },
      placeholder: ' '
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers:  [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    NgxPermissionsService,
    {
      provide: CONFIGURATION_TOKEN,
      useValue: 'to be used later for translation storage and use translation on components',
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) => {
        return (
          async () => {
            await auth.authRole();
          }
        );
      },
      deps: [AuthService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
