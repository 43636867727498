import gql from 'graphql-tag';
import {baseField, basePageableGraphqlField, responseField} from '../../base.graphql';
import {meetingLocationField} from '../../settings/location/location.graphql';
import {meetingAgendaField} from '../meeting-agenda/meeting-agenda.graphql';

export const portalMeetingAnalysisField = `
  ${baseField}
  title
  meetingAgendas {
    ${meetingAgendaField}
  }
`;

export const GET_PORTAL_MEETING_ANALYSIS = gql`query GetMinistryMeetingsForReview {
  getMinistryMeetingsForReview {
    response {
      ${responseField}
    }
    data {
      ${portalMeetingAnalysisField}
    }
  }
}
`;
