import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {getAllPortalMeetingAnalysiss, loadPortalMeetingAnalysiss} from './portal-meeting-analysis.actions';
import {SettingsService} from '../../../../services/settings.service';
import {GET_PORTAL_MEETING_ANALYSIS,} from './portal-meeting-analysis.graphql';
import {Apollo} from 'apollo-angular';
import {NgxIndexedDBService} from 'ngx-indexed-db';

@Injectable()
export class PortalMeetingAnalysisEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllPortalMeetingAnalysiss),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_PORTAL_MEETING_ANALYSIS,
      }).pipe(
        map(({data}: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            console.log(result);
            this.store.dispatch(loadPortalMeetingAnalysiss({portalMeetingAnalysiss: result?.data}));
          }
        })
      );
    })
  ), {dispatch: false});


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
    private dbService: NgxIndexedDBService,
  ) {
  }

}
