import {createAction, emptyProps, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PageableParam} from '../../page/page.model';
import {PortalMeetingAnalysis} from './portal-meeting-analysis.model';

export const loadPortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Load PortalMeetingAnalysiss',
  props<{ portalMeetingAnalysiss: PortalMeetingAnalysis[] }>()
);

export const addPortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] Add PortalMeetingAnalysis',
  props<{ portalMeetingAnalysis: PortalMeetingAnalysis }>()
);

export const upsertPortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] Upsert PortalMeetingAnalysis',
  props<{ portalMeetingAnalysis: PortalMeetingAnalysis }>()
);

export const addPortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Add PortalMeetingAnalysiss',
  props<{ portalMeetingAnalysiss: PortalMeetingAnalysis[] }>()
);

export const upsertPortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Upsert PortalMeetingAnalysiss',
  props<{ portalMeetingAnalysiss: PortalMeetingAnalysis[] }>()
);

export const updatePortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] Update PortalMeetingAnalysis',
  props<{ portalMeetingAnalysis: Update<PortalMeetingAnalysis> }>()
);

export const updatePortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Update PortalMeetingAnalysiss',
  props<{ portalMeetingAnalysiss: Update<PortalMeetingAnalysis>[] }>()
);

export const deletePortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] Delete PortalMeetingAnalysis',
  props<{ id: number }>()
);

export const deletePortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Delete PortalMeetingAnalysiss',
  props<{ ids: number[] }>()
);

export const clearPortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Clear PortalMeetingAnalysiss'
);


// export const getAllPortalMeetingAnalysiss = createAction(
//   '[PortalMeetingAnalysis/API] get all PortalMeetingAnalysiss',
//   props<{ status?: PortalMeetingAnalysisStatusEnum}>()
// );

// export const getAllPortalMeetingAnalysisByStage = createAction(
//   '[PortalMeetingAnalysis/API] get all active PortalMeetingAnalysiss by stage',
//   props<{ stage: PortalMeetingAnalysisStageEnum}>()
// );

export const getAllPortalMeetingAnalysisPageable = createAction(
  '[PortalMeetingAnalysis/API] get all active PortalMeetingAnalysiss pageable',
  props<{ pageable: PageableParam, stageName: string, meetingStatus?: string}>()
);


export const getAllPortalMeetingAnalysiss = createAction(
  '[PortalMeetingAnalysis/API] Get All Meetings'
);

export const savePortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] Save PortalMeetingAnalysis',
  props<{ meetting: any }>()
);

export const updateScheduleMeetingInviteesMaxLimit = createAction(
  '[PortalMeetingAnalysis/API] Update Meeting Invitees Limit PortalMeetingAnalysis',
  props<{ input: { meeting: string, maxInvitess: number } }>()
);

export const editPortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] edit PortalMeetingAnalysis',
  props<{ meetting: any }>()
);

export const removePortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] remove Scheduled Meeting',
  props<{ meetingUniqueId: string }>()
);

export const sendMeetingNotice = createAction(
  '[PortalMeetingAnalysis/API] send Meeting Notice',
  props<{ notice: any }>()
);

export const getMinistryMeetings = createAction(
  '[PortalMeetingAnalysis/API] get Ministry Meetings',
  props<{ ministryFilter: any }>()
);

export const publishMeeting = createAction(
  '[PortalMeetingAnalysis/API] publish Meeting',
  props<{ meetingUniqueId: string }>()
);

export const unPublishMeeting = createAction(
  '[PortalMeetingAnalysis/API] unPublish Meeting',
  props<{ meetingUniqueId: string }>()
);
export const closeWithdrawPortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] close Withdraw Scheduled Meeting',
  props<{ meetingDto: any }>()
);

export const attachSignedMinutesPortalMeetingAnalysis = createAction(
  '[PortalMeetingAnalysis/API] attach Signed Scheduled Meeting',
  props<{ input: {
      uniqueId?: string;
      attachmentName?: string;
      base64String?: string;
      attachmentExtension?: string;
    } }>()
);

export const stampSignedMinutes = createAction(
  '[PortalMeetingAnalysis/API] Stamp Signed Minutes',
  props<{ input: {
      meetingUniqueId?: string;
    } }>()
);

