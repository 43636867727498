<app-modal-fixed-header [title]="title"></app-modal-fixed-header>

<ng-container *ngIf="base64$ | async as base64; else noData">
  @if (!useIframe) {
    @if (isOnline) {
      <ng-container *ngIf="base64 != null; else noData">
        <app-custom-pdf-viewer
          [showDownloadButton]="isStaff"
          [base64Src$]="base64$"
          [filePath]="filePath"
          [filenameForDownload]="title">
        </app-custom-pdf-viewer>
      </ng-container>
    } @else {
      <div style="position: relative;">
        <div class="right-overlay">
        </div>
        <div style="width: 100%;">
          <iframe style="min-height: 100vh; width: 100%;"
                  [src]="'data:application/pdf;base64,'+base64 | safeResource"></iframe>
        </div>
      </div>
    }
  } @else {
    <div style="position: relative;">
      <div class="right-overlay">
      </div>
      <div style="width: 100%;">
        <iframe style="min-height: 100vh; width: 100%;"
                [src]="'data:application/pdf;base64,'+base64 | safeResource"></iframe>
      </div>
    </div>
  }
</ng-container>


<ng-template #noData>
  <div class="alert alert-warning text-center py-3">
    {{'No Attachment Data Found' | concat | translate}}
  </div>
</ng-template>
