import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {PortalAgendaAnalysis} from './portal-agenda-analysis.model';
import * as PortalAgendaAnalysisActions from './portal-agenda-analysis.actions';

export const portalAgendaAnalysissFeatureKey = 'portalAgendaAnalysis';

export interface State extends EntityState<PortalAgendaAnalysis> {
  // additional entities state properties
}

export function sortById(a: PortalAgendaAnalysis, b: PortalAgendaAnalysis): any {
  // return a.id.localeCompare(b.id);
}

export const adapter: EntityAdapter<PortalAgendaAnalysis> = createEntityAdapter<PortalAgendaAnalysis>({
  // sortComparer: sortById
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const PortalAgendaAnalysisReducer = createReducer(
  initialState,
  on(PortalAgendaAnalysisActions.addPortalAgendaAnalysis,
    (state, action) => adapter.addOne(action.portalAgendaAnalysis, state)
  ),
  on(PortalAgendaAnalysisActions.upsertPortalAgendaAnalysis,
    (state, action) => adapter.upsertOne(action.portalAgendaAnalysis, state)
  ),
  on(PortalAgendaAnalysisActions.addPortalAgendaAnalysiss,
    (state, action) => adapter.addMany(action.portalAgendaAnalysiss, state)
  ),
  on(PortalAgendaAnalysisActions.upsertPortalAgendaAnalysiss,
    (state, action) => adapter.upsertMany(action.portalAgendaAnalysiss, state)
  ),
  on(PortalAgendaAnalysisActions.updatePortalAgendaAnalysis,
    (state, action) => adapter.updateOne(action.portalAgendaAnalysis, state)
  ),
  on(PortalAgendaAnalysisActions.updatePortalAgendaAnalysiss,
    (state, action) => adapter.updateMany(action.portalAgendaAnalysiss, state)
  ),
  on(PortalAgendaAnalysisActions.deletePortalAgendaAnalysis,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(PortalAgendaAnalysisActions.deletePortalAgendaAnalysiss,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(PortalAgendaAnalysisActions.loadPortalAgendaAnalysiss,
    (state, action) => adapter.setAll(action.portalAgendaAnalysiss, state)
  ),
  on(PortalAgendaAnalysisActions.clearPortalAgendaAnalysiss,
    state => adapter.removeAll(state)
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return PortalAgendaAnalysisReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getPortalAgendaAnalysiss = selectAll;
