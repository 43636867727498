import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Apollo} from 'apollo-angular';
import {map, switchMap} from 'rxjs/operators';
import {SettingsService} from '../../../../services/settings.service';
import {ViewAttachmentComponent} from '../../../../shared/components/views/view-attachment/view-attachment.component';
import {GET_ATTACHMENT_CONTENT, GET_ATTACHMENTS_BY_MEETING} from './attachment.graphql';
import {getAttachmentsByMeeting, getAttachmentsByUid, loadAttachments} from './attachment.actions';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../..';
import {NotificationService} from '../../../../services/notification.service';

@Injectable()
export class AttachmentEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAttachmentsByUid),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ATTACHMENT_CONTENT,
        variables: { uid: action.uid }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result?.response?.code === 9000) {
            const fileTitle = result?.data?.fileName.replace('.aes', '');
            const extension = fileTitle.split('.')[fileTitle.split('.').length - 1];
            this.settingsService.openModal(
              {
                base64$: of(result?.data?.base64String),
                fileTitle: `${action.title}.${extension}`,
                filePath: action.uid,
                useIframe: action?.useIframe
              },
              ViewAttachmentComponent, '85%');
          }else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  attachment = createEffect(() => this.actions$.pipe(
    ofType(getAttachmentsByMeeting),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_ATTACHMENTS_BY_MEETING,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result?.response?.code === 9000) {
            this.store.dispatch(loadAttachments({ attachments: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store<AppState>,
    public settingsService: SettingsService,
    public notificationService: NotificationService
  ) {
  }
}
