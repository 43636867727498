import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {PortalAgendaAnalysis} from './portal-agenda-analysis.model';

export const loadPortalAgendaAnalysiss = createAction(
  '[PortalAgendaAnalysis/API] Load PortalAgendaAnalysiss',
  props<{ portalAgendaAnalysiss: PortalAgendaAnalysis[] }>()
);

export const addPortalAgendaAnalysis = createAction(
  '[PortalAgendaAnalysis/API] Add PortalAgendaAnalysis',
  props<{ portalAgendaAnalysis: PortalAgendaAnalysis }>()
);

export const upsertPortalAgendaAnalysis = createAction(
  '[PortalAgendaAnalysis/API] Upsert PortalAgendaAnalysis',
  props<{ portalAgendaAnalysis: PortalAgendaAnalysis }>()
);

export const addPortalAgendaAnalysiss = createAction(
  '[PortalAgendaAnalysis/API] Add PortalAgendaAnalysiss',
  props<{ portalAgendaAnalysiss: PortalAgendaAnalysis[] }>()
);

export const upsertPortalAgendaAnalysiss = createAction(
  '[PortalAgendaAnalysis/API] Upsert PortalAgendaAnalysiss',
  props<{ portalAgendaAnalysiss: PortalAgendaAnalysis[] }>()
);

export const updatePortalAgendaAnalysis = createAction(
  '[PortalAgendaAnalysis/API] Update PortalAgendaAnalysis',
  props<{ portalAgendaAnalysis: Update<PortalAgendaAnalysis> }>()
);

export const updatePortalAgendaAnalysiss = createAction(
  '[PortalAgendaAnalysis/API] Update PortalAgendaAnalysiss',
  props<{ portalAgendaAnalysiss: Update<PortalAgendaAnalysis>[] }>()
);

export const deletePortalAgendaAnalysis = createAction(
  '[PortalAgendaAnalysis/API] Delete PortalAgendaAnalysis',
  props<{ id: number }>()
);

export const deletePortalAgendaAnalysiss = createAction(
  '[PortalAgendaAnalysis/API] Delete PortalAgendaAnalysiss',
  props<{ ids: number[] }>()
);

export const clearPortalAgendaAnalysiss = createAction(
  '[PortalAgendaAnalysis/API] Clear PortalAgendaAnalysiss'
);


// export const getAllPortalAgendaAnalysiss = createAction(
//   '[PortalAgendaAnalysis/API] get all PortalAgendaAnalysiss',
//   props<{ status?: PortalAgendaAnalysisStatusEnum}>()
// );

// export const getAllPortalAgendaAnalysisByStage = createAction(
//   '[PortalAgendaAnalysis/API] get all active PortalAgendaAnalysiss by stage',
//   props<{ stage: PortalAgendaAnalysisStageEnum}>()
// );


export const getAllPortalCurrentAgendaAnalysis = createAction(
  '[PortalAgendaAnalysis/API] Get All Portal Current Agenda Analysis',
  props<{ agendaUniqueId: string }>()
);

export const getAllPortalAgendaAnalysisHistory = createAction(
  '[PortalAgendaAnalysis/API] Get All Portal Agenda Analysis History',
  props<{ agendaUniqueId: string }>()
);

export const savePortalAgendaAnalysis = createAction(
  '[PortalAgendaAnalysis/API] Save PortalAgendaAnalysis',
  props<{
    input: {
      agendaUniqueId: string,
      review: string,
      documentName?: string,
      documentPath?: string
    }
  }>()
);
