// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  ENCRYPT_SESSION: false,
  CLIENT_ID: 'bf8d043e07af58f1d8d0',
  CLIENT_SECRET: '052c1ef326864fbc17444f573c930949e899a53b',
  INDEXED_DB_VERSION: 9,

  // research
  // SERVER_URL: 'http://196.192.79.210:8082',
  // GRAPHQL_API: 'https://ecabinet.all.co.tz:8092',

  SERVER_URL: 'https://ecabinet.egatest.go.tz/ruhusu',
  GRAPHQL_API: 'https://ecabinet.egatest.go.tz/gateway/',
};
// Changes
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
